(function($){

    console.log("Home!");

    var $slide = $('#gallery');

    $slide.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        arrows: false,
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        cssEase: 'cubic-bezier(0.19,1,.22,1)',
        mobileFirst: true,
        variableWidth: true,
        touchThreshold: 10,
        lazyLoad: 'progressive',
        responsive: [
            {
                breakpoint: 375-1,
                settings: {

                }
            },
            {
                breakpoint: 768-1,
                settings: {

                }
            },
            {
                breakpoint: 992-1,
                settings: {
                    arrows: true,
                }
            },
            {
                breakpoint: 1200-1,
                settings: {
                    arrows: true,
                }
            },
        ]
    });

    $slide.on('mousedown', function(event){
        $(this).addClass('dragging');
    });
     $slide.on('mouseup', function(event){
        $(this).removeClass('dragging');
    });

    setTimeout(function() {
        $('#header').addClass('show');
        $('#gallery').addClass('show');
    }, 500);

    setTimeout(function() {
        $('#logo').addClass('show');
    }, 1500);

    setTimeout(function(){
        $('#gallery').addClass('blink-arrows');
    }, 1000);

})(jQuery);